var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"hp-ecommerce-detail-card hp-card-6 overflow-hidden"},[(this.$store.state.themeConfig.theme == 'dark')?_c('div',{staticClass:"position-absolute w-100 h-100",style:({
      top: 0,
      right: 0,
      backgroundImage:
        'url(' + require('@/assets/img/dashboard/ecommerce-card-bg-dark.png') + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    })}):_c('div',{staticClass:"position-absolute w-100 h-100",style:({
      top: 0,
      right: 0,
      backgroundImage:
        'url(' + require('@/assets/img/dashboard/ecommerce-card-bg.png') + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    })}),_c('b-row',[_c('b-col',{staticClass:"position-relative",attrs:{"cols":"7"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("New VR-07")]),_c('span',{staticClass:"hp-p1-body d-block text-black-60 hp-text-color-dark-50"},[_vm._v(" By Google ")]),_c('b-button',{staticClass:"btn-ghost mt-16",attrs:{"variant":"primary"}},[_vm._v(" Check Detail ")])],1),_c('img',{attrs:{"src":require('@/assets/img/dashboard/ecommerce-card-img.png'),"alt":"New VR-07"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }